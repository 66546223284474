import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

// 言語jsonファイルのimport
import translation_en from "./en.json";
import translation_es from "./es.json";
import translation_ja from "./ja.json";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const detector = new LanguageDetector(null, {
    order: ['querystring', 'cookie', 'navigator', 'localStorage', 'htmlTag'],
    htmlTag: document.documentElement,
});

const resources = {
    en: {
        translation: translation_en
    },
    es: {
        translation: translation_es
    },
    ja: {
        translation: translation_ja
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        resources,
        // lng: "ja",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;

