// import React from 'react';
import { lazy, Suspense } from "react";
import { Routes, Route, Link } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
// import { AnimatePresence, motion } from "framer-motion";

// import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';

import useLocationChange from './components/hooks/useLocationChange'
import { useTranslation } from 'react-i18next';

import { Header } from './components/modules/Header'

import './App.css';

// const Page = lazy(() => import('./components/modules/Page'));
const TitlePage = lazy(() => import('./components/pages/TitlePage'));
const IntroductionPage = lazy(() => import('./components/pages/IntroductionPage'));
const VideosPage = lazy(() => import('./components/pages/VideosPage'));
const IllustrationPage = lazy(() => import('./components/pages/IllustrationPage'));
const GamePage = lazy(() => import('./components/pages/GamePage'));
const GamePageExecute = lazy(() => import('./components/pages/GamePageExecute'));
const NotFound = lazy(() => import('./components/pages/NotFound'));

// import BackGround from "./images/background.png"
// import { url } from "inspector";

// import { createStyles } from '@mui/material/styles';

// function Copyright() {
//   return (
//     <Typography variant="body2" /*color="text.secondary"*/ align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

function App() {
  // const navigation = useNavigate();
  // const trans = useTranslation();
  const { t } = useTranslation();

  useLocationChange((location) => {
    // console.log(`useLocationChange: ${location.pathname}`);
    const path = location.pathname.substring(1).replace(".html", "");

    if (path.length > 0) {
      const trans_name = `header.${path}`;
      window.document.title = t("header.title") + " | " + t(trans_name);
    }
    else {
      window.document.title = t("header.title");
    }
  })

  const pages = [
    { path: '/', element: <TitlePage /> },
    { path: '/introduction', element: <IntroductionPage /> },
    { path: '/videos', element: <VideosPage /> },
    { path: '/illustration', element: <IllustrationPage /> },
    { path: '/games', element: <GamePage /> },
    { path: '/games/jumping', element: <GamePageExecute path='./../../../data/games/jumping/Build/' width={9} height={16} /> },
    { path: '/games/money', element: <GamePageExecute path='./../../../data/games/money/Build/' width={16} height={9} /> },
  ];

  return (
    <div className="App" >
      <div className="App-header">
        <Header />
      </div>

      <div className="App-body">
        <Suspense fallback={<div className="App-Fallback">Loading...</div>}>
          <Routes>
            {pages.map((element, index) => {
              return (
                <Route path={element.path} element={element.element} key={index} />
              );
            })}

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>

        {pages.map((element, index) => {
          return (<Link to={element.path} style={{ display: 'none' }} key={index} />);
        })}
      </div >

      {/* Footer */}
      {/* <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
        <Copyright />
      </Box> */}
      {/* End footer */}
    </div>
  );
}

export default App;
