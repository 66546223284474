import React from 'react';
// import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import './index.css';
import "./i18n/config"

const rootElement = document.getElementById("root");
if (rootElement) {
  if (rootElement.hasChildNodes()) {
    hydrate(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>,
      </React.StrictMode >
      , rootElement)
  }
  else {
    render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>,
      </React.StrictMode >
      , rootElement);
  }
}

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//   </React.StrictMode >,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
