import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
// import { IconButton } from '@mui/material';
// import { Icon } from '@iconify/react';

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import logo from './../../images/cc1111_trans.webp';
// import jp4x3 from '@iconify/icons-flag/jp-4x3';
// import us4x3 from '@iconify/icons-flag/us-4x3';
// import es4x3 from '@iconify/icons-flag/es-4x3';

import "./Header.css"
import "./../../index.css"

const styles = (): any => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "#61B598",
        color: "#ffffff",
        stroke: "#000000",
        strokeWidth: "4",
        height: "80px",
    },
    menuLogo: {
        width: "inherit",
        height: "inherit",
        PointerEvent: "none",
    },
    menuButton: {
        margin: "auto 0 0 auto",
        width: 'inherit',
        // backgroundColor: '#000000',
    },
    title: {
        flexGrow: 1,
    },
    text: {
        color: "#ffffff",
        textShadow: "2px 2px 2px #000000, -2px 2px 2px #000000, 2px -2px 2px #000000, -2px -2px 2px #000000",
        fontFamily: "M PLUS Rounded 1c",
    },
    lang: {
        width: '160px',
        height: '40px',
        // flex: 'true',
        // alignContent: 'end',
        // flexDirection: 'row',
        backgroundColor: '#000000',
    },
})

const buttonStyles = (): any => ({
    text: {
        color: "#ffffff",
        textShadow: "1px 1px 2px #000000, -1px 1px 2px #000000, 1px -1px 2px #000000, -1px -1px 2px #000000",
        fontFamily: "M PLUS Rounded 1c",
    },
    textSelect: {
        color: "#ffffff",
        textShadow: "1px 1px 2px #000000, -1px 1px 2px #000000, 1px -1px 2px #000000, -1px -1px 2px #000000",
        fontFamily: "M PLUS Rounded 1c",
        backgroundColor: '#529980',
    },
})

export const Header: React.FC = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const handleToAboutPage = (a_page: string) => {
        navigation(a_page);
    }

    const classes = styles();
    const buttonClasses = buttonStyles();

    // const font_size = window.innerWidth * 0.025;
    const font_size = Math.sqrt(window.innerWidth) * 1.0;

    const buttons = [
        { path: '/', name: t("header.home") },
        { path: '/introduction', name: t("header.introduction") },
        { path: '/videos', name: t("header.videos") },
        { path: '/illustration', name: t("header.illustration") },
        { path: '/games', name: t("header.games") },
    ];

    // console.log(`pathname: ${location.pathname}`);

    return (
        <div style={classes.root}>
            <AppBar position="fixed">
                <Toolbar sx={classes.appBar}>
                    <img src={logo} style={classes.menuLogo} alt="logo" />
                    {/* <div className='Header-Title' style={{ fontSize: font_size }}>{t("header.title")}</div> */}
                    <h1 className='Header-Title' style={{ fontSize: font_size }}>{t("header.title")}</h1>

                    <div style={classes.menuButton}>
                        {buttons.map((element, index) => {
                            let style = buttonClasses.text;
                            if (element.path === location.pathname) {
                                style = buttonClasses.textSelect;
                            }

                            return (<Button onClick={() => { handleToAboutPage(element.path); }} color="inherit" sx={style} key={index}>{element.name}</Button>);
                        })}

                        {/* <Button onClick={() => { handleToAboutPage(""); }} color="inherit" sx={buttonClasses.text}>{t("header.home")}</Button>
                        <Button onClick={() => { handleToAboutPage("/introduction"); }} color="inherit" sx={buttonClasses.text}>{t("header.introduction")}</Button>
                        <Button onClick={() => { handleToAboutPage("/illustration"); }} color="inherit" sx={buttonClasses.text}>{t("header.illustration")}</Button>
                        <Button onClick={() => { handleToAboutPage("/games"); }} color="inherit" sx={buttonClasses.text}>{t("header.games")}</Button> */}
                    </div>

                    {/* <div style={classes.lang}>
                        <IconButton>
                            <Icon icon={us4x3} />
                        </IconButton>
                        <IconButton>
                            <Icon icon={es4x3} />
                        </IconButton>
                        <IconButton>
                            <Icon icon={jp4x3} />
                        </IconButton>
                    </div> */}
                </Toolbar>
            </AppBar>
        </div>
    );
}

