import { useEffect, useRef } from 'react'
import { Location, useLocation } from 'react-router-dom'

declare global {
    interface Window {
        gtag?: (key: string, trackingId: string, config: { page_path: string }) => void;
    }
}

const useLocationChange = (callback: (location: Location) => void) => {
    const refCallback = useRef<undefined | ((location: Location) => void)>()
    const location = useLocation()

    useEffect(() => {
        refCallback.current = callback
    }, [callback, location.pathname])

    // ロケーションに変更があったときに処理実行
    useEffect(() => {
        const trackingId = process.env.REACT_APP_GA_MEASUREMENT_ID;

        if (window.gtag && trackingId) {
            window.gtag('config', trackingId, { page_path: location.pathname });
            // console.log(`send gtag[location]: ${location.pathname}`);
        }
        else {
            console.log('Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.');
        }

        if (refCallback.current) {
            refCallback.current(location)
        }
    }, [location])
}

export default useLocationChange

